import { alpha } from '@mui/material'

const colors = {
  // Primary Colors
  blue: '#64D0E4',
  white: '#fff',
  black: '#000',
  secondaryOrange: '#F18500',
  darkBlue: '#3B85A6',
  mutedBlue: '#68A2B8',
  secondaryRed: '#BE1E20',

  // Secondary Colors
  yellow: '#FFD200',
  orange: '#FF9016',
  green: '#85B09B',
  lightGreen: '#05A76D',
  darkGreen: '#718C45',
  lightBeige: '#f9f6eb',
  grey: '#ccc',
  turquoise: '#008BAC',
  red: '#D8292F',
  purple: '#A56ED3',
  gold: '#f8b015',

  // Shades
  blueMedium: alpha('#64D0E4', 0.6),
  orangeMedium: alpha('#FF9016', 0.6),
  whiteMedium: alpha('#fff', 0.6),
  blackMedium: alpha('#000', 0.6),

  // Button State Colors
  orangeHover: '#F28915',
  orangeFocused: '#F28915',
  whiteHover: '#F7F7F7',
  whiteFocused: '#F0F0F0',
  blueHover: '#5FC6D9',
  blueFocused: '#5ABCCE',

  // American Blend Colors
  beige: '#D0B393',
  deepGreen: '#355933',
  deepRed: '#AC0712',
  deepBlue: '#00516E',

  // Story Ads Badge Color
  storyAdsBadge: '#D11828',
}

export default colors
