import { ObjectValues } from 'jticxm-types'

const NAS_THEME_NAMES = {
  BEIGE: 'beige',
  BLUE: 'blue',
  CERULEAN: 'cerulean',
  GREEN: 'green',
  ORANGE: 'orange',
  DEFAULT: 'default',
  VIOLET: 'violet',
  TRANSPARENT: 'transparent',
} as const

export default NAS_THEME_NAMES
export type NasThemeName = ObjectValues<typeof NAS_THEME_NAMES>
