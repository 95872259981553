import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'

export default function createOverrides(): CXMThemeOverrides {
  return {
    typography: {
      body1: { color: colors.mutedBlue },
      body2: { color: colors.mutedBlue },
      h1: { color: colors.mutedBlue },
      h2: { color: colors.mutedBlue },
      h3: { color: colors.mutedBlue },
      h4: { color: colors.mutedBlue },
      h5: { color: colors.mutedBlue },
      h6: { color: colors.mutedBlue },
    },
    components: {
      CxmPageXmas: {
        styleOverrides: {
          doorContents: {
            backgroundColor: colors.white,
            border: `4px dashed ${colors.mutedBlue}`,
            '& > h6': {
              color: colors.mutedBlue,
            },
            '& > button': {
              fontSize: '2rem',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {},
          containedPrimary: {
            color: colors.white,
            '&.Mui-disabled': {
              color: colors.white,
            },
          },
          textPrimary: {},
        },
      },
      CxmLink: {
        styleOverrides: {
          link: {
            fontWeight: 500,
            color: colors.white,
            '&:hover': {
              textDecoration: `underline ${colors.white} 2px`,
            },
            '& > a': {
              color: colors.mutedBlue,
              '&:hover': {
                textDecoration: `underline ${colors.white} 2px`,
              },
            },
          },
        },
      },
      CxmLegalMenu: {
        styleOverrides: {
          footnote: {
            color: colors.black,
          },
          legalMenu: {
            backgroundColor: colors.white,

            '& > a': {
              color: colors.black,
            },
            '& > a:hover': {
              color: colors.black,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: colors.white,
          },
        },
      },
    },
  }
}
