import NAS_THEME_NAMES from 'nas-de/src/theme/names'

import beigeTheme from './beige'
import blueTheme from './blue'
import ceruleanTheme from './cerulean'
import defaultTheme from './default'
import greenTheme from './green'
import orangeTheme from './orange'
import transparentTheme from './transparent'
import violetTheme from './violet'

export default class NasTheme {
  public static get(themeName) {
    switch (themeName) {
      case NAS_THEME_NAMES.BEIGE:
        return beigeTheme
      case NAS_THEME_NAMES.BLUE:
        return blueTheme
      case NAS_THEME_NAMES.GREEN:
        return greenTheme
      case NAS_THEME_NAMES.ORANGE:
        return orangeTheme
      case NAS_THEME_NAMES.CERULEAN:
        return ceruleanTheme
      case NAS_THEME_NAMES.VIOLET:
        return violetTheme
      case NAS_THEME_NAMES.TRANSPARENT:
        return transparentTheme
      default:
        return defaultTheme
    }
  }
}
